import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const addListeners = async (navigate) => {
  await PushNotifications.addListener('registration', (token) => {
    console.info('Registration token: ', token.value);
  });

  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    console.log('Push notification action performed', notification.actionId, notification.notification);
    handleNotificationClick(navigate);
  });
};

const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
};

const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
};

const handleNotificationClick = (navigate) => {
  const shiftId = '';
  const type =''
  switch (type) {
    case 'expense':
      navigate(`/staff/shift-details/${shiftId}/timeline/add-mileage-and-expense`);
      break;
    case 'mileage':
      navigate(`/staff/shift-details/${shiftId}/timeline/add-mileage-and-expense`);
      break;
    case 'travel_distance':
      navigate(`/staff/shift-details/${shiftId}/timeline/review-locations`);
      break;
    case 'time_entry':
      navigate(`/staff/shift-details/shift-time/${shiftId}`);
      break;
    case 'tasks':
      navigate(`/staff/shift-details/${shiftId}/timeline/shift-task`);
      break;
    case 'clientMood':
      navigate(`/staff/shift-details/${shiftId}/timeline/clients-mood`);
      break;
    case 'incident':
      navigate(`/staff/shift-details/${shiftId}/timeline/incident-or-near-miss`);
      break;
    case 'rating':
      navigate(`/staff/shift-details/${shiftId}/timeline/shift-rating`);
      break;
    case 'shift_notes':
      navigate(`/staff/shift-details/${shiftId}/timeline/add-shift-notes`);
      break;
    case 'post_it':
      const postItId = ''; //Need post it id for post it
      navigate(`/staff/add-post-it?postItId=${postItId}`);
      break;
    case 'progress_notes':
      navigate(`/staff/shift-details/${shiftId}/progress/add-progress-notes`);
      break;
    case 'feedback':
      navigate(`/staff/shift-details/${shiftId}/progress/add-feedback`);
      break;
    case 'incident_progress':
      navigate(`/staff/shift-details/${shiftId}/progress/add-incident`);
      break;
    case 'enquiry':
      navigate(`/staff/shift-details/${shiftId}/progress/add-enquiry`);
      break;
    case 'goal':
      navigate(`/staff/shift-details/${shiftId}/progress/add-goal`);
      break;
    case 'shift_info':
      navigate(`/staff/shift-details/${shiftId}`);
      break;
    default:
      break;
  }
  // window.location.pathname = '/staff/my-doc';
};

export const PushNotificationHelper = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    registerNotifications().then(() => {
      addListeners(navigate);
    });
  }, []);
  return <></>;
};
