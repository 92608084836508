import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import imageCompression from 'browser-image-compression';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import moment from 'moment';

// Image
import svg from '../Assets/svg';
import ErrorLogo from '../Assets/Images/Icons/somethingWentWrongError.svg';
import { Button, Modal } from 'antd';

/** Environment */
export const API_URL = process.env.REACT_APP_API_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export let BASE_URL = process.env.REACT_APP_BASE_URL;
export const alias = '/at-scheduling';
const SECRET_KEY = process.env.REACT_APP_DECRYPTION_KEY;

/** User Roles */
export const ROLE = {
  ADMIN: 'admin',
  CLIENT: 'client',
  BUSINESS_ADMIN: 'business_admin',
  CARER: 'carer',
};

// switch (IS_DEV) {
//   case 0:
//     API_URL = `http://localhost:4000`;
//     API_KEY = 'U2FsdGVkX1+fgFI+LaRuu/M3zLI5dpwJSIsLGgvVlcCzw28WICpZlLsA4Aety0+gnP5EPuhHJM18lSBu5SDtrQ==';
//     BASE_URL = 'http://localhost:3000/';
//     break;
//   case 1:
//     API_URL = `https://at.3rddigital.com/at-scheduling-backend`;
//     API_KEY = `U2FsdGVkX1+ySpfcC43WwcFSQQMbpt6N/nuIH3OrZjO3bQ71ofeN1lRMb8NKYbtU04e7WS5gGqduFmuLskiLow==`;
//     BASE_URL = 'https://at.3rddigital.com';
//     break;
//   default:
// }

// Firebase;
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
export const VAPID_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY;
export const SERVICE_WORKER_REGISTER = process.env.REACT_APP_FIREBASE_SERVICE_WORKER_REGISTER;

/** Common Variables */
export const EMAIL_REGEX = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const ONLY_NUMBERS_REGEX = /^[0-9]+$/;
export const ONLY_ALPHABETS_REGEX = /^[A-Za-z ]+$/;

/** Date Functions Starts */
export const dayjsFormat = 'YYYY-MM-DD';
export const dayjsFormatTime = 'YYYY-MM-DD hh:mm A';
export const lifetimeDate = new Date(dayjs('01-01-2024').format(dayjsFormat));
const day = new Date(dayjs('01-01-2024').format(dayjsFormat)).getDate();
const month = new Date(dayjs('01-01-2024').format(dayjsFormat)).getMonth() + 1;
const year = new Date(dayjs('01-01-2024').format(dayjsFormat)).getFullYear();
export const momentDDMMYY = (data) => moment(data).format(dayjsFormat);
export const momentDateTime = (data) => moment(data).format(dayjsFormatTime);
export const lifetimeDateWithFormat = `${year}-${month < 9 ? `0${month}` : month}-${day < 9 ? `0${day}` : day}`;

/** Date Functions Ends */

/** Error Handling Functions Starts */
export const dispatchLoading = (dispatch, scope, status) =>
  dispatch({ type: 'SET_LOADING', payload: { scope, status } });
export const dispatchToast = (dispatch, scope, status) => dispatch({ type: 'SET_TOAST', payload: { scope, status } });
export const dispatchError = (dispatch, scope, status) => dispatch({ type: 'SET_ERROR', payload: { scope, status } });
export const catchHandler = (dispatch, scope) => (err) => {
  if (err.code === 'ERR_NETWORK') dispatchToast(dispatch, 'error', 'Unable to connect to server');
  else if (err?.response?.status !== 401) dispatchError(dispatch, scope, err?.response?.data?.error);
  else dispatchToast(dispatch, 'error', err?.response?.data?.msg);
  dispatchLoading(dispatch, scope, false);
};
export const elseHandler = (dispatch, scope, data) => {
  dispatchToast(dispatch, 'error', data?.msg);
  dispatchError(dispatch, scope, data?.error);
};

export const ErrorFallbackPage = ({ error, resetErrorBoundary }) => {
  const [modalVisible, setModalVisible] = useState(false);
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  // Use Navigate
  const navigate = useNavigate();

  // Use Translation
  const { t } = useTranslation();

  // Use location
  const { pathname } = useLocation();

  // Use Ref
  const originalPathname = useRef(pathname);

  // Goto previous Page
  const goToBack = () => {
    navigate(-1);
  };

  // Use Effect
  useEffect(() => {
    if (pathname !== originalPathname.current) resetErrorBoundary();
  }, [pathname, resetErrorBoundary]);

  const toggleModal = () => {
    setModalVisible((prevState) => !prevState);
  };

  return (
    <div className="text-center w-100 d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
      <div>
        <div className="px-3 mb-4 header-logo">
          <img src={ErrorLogo} alt="header logo" className="img-fluid" onClick={(e) => navigate('/')} />
        </div>
        <h5 className="ff_rg mb-1 f18">{t('error.somethingWrong')}</h5>
        <p className="ff_rg f14">
          {t('error.thereWasProblem')}
          <span className="d-block ff_rg f14 mx-3">{t('error.pleaseRefresh')}</span>
        </p>
        <p className="ff_rg f14 mt-3 text-secondary">
          {t('error.somethingNot')} ?{' '}
          <span className="ff_md f14 text-dark">
            {t('error.contact')} :{' '}
            <Link className="ff_md f14 text-dark" to="mailto: support@companyname.com">
              support@companyname.com
            </Link>{' '}
          </span>
        </p>

        <button className="btn btn-primary text-uppercase fs-6 px-3 txt-white mt-3" onClick={() => goToBack()}>
          {t('error.goBack')}
        </button>
        <br />
        <Link className="d-block mt-3 cursor-pointer text-decoration-underline" onClick={() => toggleModal()}>
          View Error
        </Link>
      </div>
      <Modal title="Error Details" open={modalVisible} onCancel={toggleModal} footer={''}>
        <p>{String(error)}</p>
      </Modal>
    </div>
  );
};
/** Error Handling Functions Ends */

/**
 * Protected Route for Client
 * @param {Object} routeComponent - Object with route data
 * @param {String} routeComponent.roleName - Role of user
 * @param {Function} routeComponent.children - Child component / page
 * @returns {Function} Child Component
 */
export const ProtectedRoute = ({ roleName, children, navigate }) => {
  const { role } = useSelector((state) => state.auth);
  // const navigate = useNavigate();
  if (role === roleName) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    navigate('/');
  }
};

/**
 * Protected Route for Admin
 * @param {Object} routeComponent - Object with route data
 * @param {String} routeComponent.roleName - Role of user
 * @param {Function} routeComponent.children - Child component / page
 * @returns {Function} Child Component
 */
export const ProtectedRouteAdmin = ({ roleName, children }) => {
  const { role, isLoggedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (isLoggedIn === true && role === roleName) return <React.Fragment>{children}</React.Fragment>;
  else navigate(`/auth/login`, { replace: true });
};

/** Loader */
export const SiteLoader = ({ text }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  if (pathname === '/') {
    return null;
  }
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        width: 'calc(100% - 1rem)',
      }}
    >
      <div className="Loader">
        <div className="d-block">
          <div className="spinner-border" role="status"></div>
        </div>
      </div>
      <p className="ff" style={{ fontSize: '1.2rem', lineHeight: '1.1' }}>
        {text !== undefined ? text : t('pleaseWait')}
      </p>
    </div>
  );
};

/** Global Button Loader */
export const BtnLoader = ({ className }) => {
  return (
    <div className={`d-block ${className}`}>
      <div className="spinner-border" role="status"></div>
    </div>
  );
};

export const ButtonLoader = ({ label, className, onClick, loader, svg, type, disabled }) => {
  return (
    <Button loading={loader} onClick={onClick} className={`${className} ButtonLoader`} type={type} disabled={disabled}>
      {svg && <>{svg} &nbsp;</>}
      {label}
    </Button>
  );
};

/** Function to compress image before API call */
export const imageCompressorFn = async (file, setState) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compFile = await imageCompression(file, options);
    setState(compFile);
  } catch (error) {
    setState(file);
  }
};

/** Function to Decrypt the data */
export function decrypt(text) {
  if (!text) {
    return '';
  }
  try {
    let secretKey = SECRET_KEY;
    const keyUtf = CryptoJS.enc.Utf8.parse(secretKey);
    const iv = CryptoJS.enc.Utf8.parse('678025308de70905');
    if (text) {
      const decrypted = CryptoJS.AES.decrypt(text, keyUtf, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      });
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      return decryptedText;
    }
  } catch (error) {
    console.error('Error decrypting text:', error);
    throw error;
  }
}

// Close Icon Image
export const CloseIcon = () => <span>{svg?.modalClose}</span>;

export const formatDate = (date) => {
  const dateObj = new Date(date);

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// Drawer Close Icon

export const DrawerCloseIcon = () => {
  return (
    <button
      type="button"
      className="btn btn-outline-secondary sqareBtn close__btn btn-lg m-0 text-reset"
      id="closeOffcanvas"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    >
      <svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="18.6569"
          y="5.92896"
          width={2}
          height={18}
          rx={1}
          transform="rotate(45 18.6569 5.92896)"
          fill="black"
        />
        <rect
          x="5.92896"
          y="7.34314"
          width={2}
          height={18}
          rx={1}
          transform="rotate(-45 5.92896 7.34314)"
          fill="black"
        />
      </svg>
    </button>
  );
};

export const DrawerBackIcon = ({ onClick }) => {
  return (
    <button
      type="button"
      className="btn btn-outline-secondary sqareBtn close__btn btn-lg m-0 me-md-4 me-2 text-reset drawerBackIcon"
      id="closeOffcanvas"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      onClick={onClick}
    >
      <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.7075 1.70723L6.41461 8.00014L12.7075 14.293C12.8931 14.481 12.9969 14.7347 12.9961 14.9989C12.9953 15.263 12.89 15.5162 12.7032 15.703C12.5164 15.8897 12.2633 15.995 11.9991 15.9958C11.735 15.9966 11.4812 15.8929 11.2933 15.7072L4.29329 8.70724C4.10576 8.5197 4.00041 8.26535 4.00041 8.00014C4.00041 7.73493 4.10576 7.48058 4.29329 7.29305L11.2933 0.293046C11.4812 0.107391 11.735 0.00363552 11.9991 0.00443753C12.2633 0.00523955 12.5164 0.110534 12.7032 0.297326C12.89 0.484118 12.9953 0.737234 12.9961 1.0014C12.9969 1.26556 12.8931 1.51931 12.7075 1.70723Z"
          fill="black"
        />
      </svg>
    </button>
  );
};

export const timeAgo = (dateString) => {
  const now = new Date();
  const date = new Date(dateString);
  const secondsAgo = Math.floor((now - date) / 1000);

  let interval = Math.floor(secondsAgo / 31536000);
  if (interval >= 1) {
    return interval === 1 ? '1 year ago' : `${interval} years ago`;
  }

  interval = Math.floor(secondsAgo / 2592000);
  if (interval >= 1) {
    return interval === 1 ? '1 month ago' : `${interval} months ago`;
  }

  interval = Math.floor(secondsAgo / 86400);
  if (interval >= 1) {
    return interval === 1 ? '1 day ago' : `${interval} days ago`;
  }

  interval = Math.floor(secondsAgo / 3600);
  if (interval >= 1) {
    return interval === 1 ? '1 hour ago' : `${interval} hours ago`;
  }

  interval = Math.floor(secondsAgo / 60);
  if (interval >= 1) {
    return interval === 1 ? '1 minute ago' : `${interval} minutes ago`;
  }

  return secondsAgo <= 1 ? 'just now' : `${secondsAgo} seconds ago`;
};

// Function to calculate the number of week shift occurrences
export function calculateWeekOccurrences(startDate, endDate, weekCount, daysPerWeek) {
  // Convert the input dates to JavaScript Date objects if they are not already
  let start = new Date(startDate);
  let end = new Date(endDate);

  // Calculate the difference in time between the start and end dates
  let timeDiff = end - start;

  // Calculate the difference in days
  let daysDiff = timeDiff / (1000 * 3600 * 24);

  // Calculate the total number of weeks in the given period
  let totalWeeks = daysDiff / 7;

  // Calculate the number of occurrences based on the weekCount and daysPerWeek
  let occurrences = Math.floor(totalWeeks / weekCount) * daysPerWeek;

  // Include occurrences for the remaining days in the final week if there are any
  let remainingDays = daysDiff % (weekCount * 7);
  if (remainingDays > 0) {
    occurrences += Math.min(daysPerWeek, Math.ceil(remainingDays / 7) * daysPerWeek);
  }

  return occurrences;
}

// Function to calculate the number of day shift occurrences
export function calculateOccurrencesByDays(startDate, endDate, dayInterval) {
  // Convert the input dates to JavaScript Date objects if they are not already
  let start = new Date(startDate);
  let end = new Date(endDate);

  // Calculate the difference in time between the start and end dates
  let timeDiff = end - start;

  // Calculate the difference in days
  let daysDiff = timeDiff / (1000 * 3600 * 24);

  // Calculate the number of occurrences based on the day interval
  let occurrences = Math.floor(daysDiff / dayInterval) + 1;

  return occurrences;

  // Function to calculate the number of month shift occurrences
}

export function calculateOccurrencesByMonths(startDate, endDate, monthInterval, dayOfMonth) {
  // Convert the input dates to JavaScript Date objects if they are not already
  let start = new Date(startDate);
  let end = new Date(endDate);

  // Initialize the count of occurrences
  let occurrences = 0;

  // Loop through each month, incrementing by the monthInterval
  while (start <= end) {
    // Check if the current month has the dayOfMonth
    let daysInMonth = new Date(start.getFullYear(), start.getMonth() + 1, 0).getDate();
    if (dayOfMonth <= daysInMonth) {
      occurrences++;
    }

    // Move to the next interval
    start.setMonth(start.getMonth() + monthInterval);
    start.setDate(dayOfMonth);
  }

  return occurrences;
}

export function transformString(input) {
  // Split the string by underscores
  const words = input?.split('_');

  // Capitalize the first letter of each word
  const capitalizedWords = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words with spaces
  return capitalizedWords?.join(' ');
}

export const constructDate = (dateString) => {
  // Parse the date string into a Date object
  const date = new Date(dateString);

  // Extract the components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  // const seconds = String(date.getSeconds()).padStart(2, '0');

  // Format the date and time
  return `${year}-${month}-${day} , ${hours}:${minutes}`;
};

export const formatTime = (time) => {
  let formattedHour = time.hour % 12;
  if (formattedHour === 0) {
    formattedHour = 12;
  }
  const period = time.hour < 12 ? 'AM' : 'PM';

  return `${formattedHour}:${time.min < 10 ? '0' : ''}${time.min}${period}`;
};

export const formatDateTime = (isoDateString) => {
  const date = new Date(isoDateString);

  const dateOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    weekday: 'short',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
  let formattedTime = date.toLocaleTimeString('en-GB', timeOptions);

  formattedTime = formattedTime.replace(/(am|pm)/i, (match) => match.toUpperCase());

  const htmlString = `
      <h6 class="fw-bold text-primary">
          ${formattedDate} <span class="text-949494 fw-normal ms-2">${formattedTime}</span>
      </h6>
  `;

  return htmlString;
};

// Function to disable dates greater than today's date
export const disableFutureDates = (current) => {
  return current && current > dayjs().endOf('day');
};

// export const StartTime = (data) => {
//   // Extracting values from inputData
//   const { hour, min } = data;

//   let adjustedHour = hour;
//   let period = 'AM';

//   if (hour === 0) {
//     adjustedHour = 12;
//     period = 'PM';
//   } else if (hour === 12) {
//     adjustedHour = 12;
//     period = 'AM';
//   } else if (hour > 12) {
//     adjustedHour = hour - 12;
//     period = 'PM';
//   } else {
//     period = 'AM';
//   }

//   // Constructing moment object
//   const startDate = moment(new Date()).set({
//     hour: adjustedHour,
//     minute: min || 0,
//   });

//   // Formatting time
//   const formattedTime = startDate.format('hh:mm') + ` ${period}`;
//   return formattedTime;
// };

// export const EndTime = (data) => {
//   // Extracting values from inputData
//   const { hour, min } = data;

//   let adjustedHour = hour;
//   let period = 'AM';

//   if (hour === 0) {
//     adjustedHour = 12;
//     period = 'PM';
//   } else if (hour === 12) {
//     adjustedHour = 12;
//     period = 'AM';
//   } else if (hour > 12) {
//     adjustedHour = hour - 12;
//     period = 'PM';
//   } else {
//     period = 'AM';
//   }

//   // Constructing moment object
//   const endDate = moment(new Date()).set({
//     hour: adjustedHour,
//     minute: min || 0,
//   });

//   // Formatting time
//   const formattedTime = endDate.format('hh:mm') + ` ${period}`;
//   return formattedTime;
// };
