import axios from 'axios';
import { API_URL, dispatchError, dispatchLoading, dispatchToast, elseHandler } from '../helpers/utils';

export const FCM_TOKEN = (data) => async (dispatch) => {
  dispatchLoading(dispatch, 'token', true);
  axios
    .post(API_URL + '/api/save-fcm-token', data)
    .then((result) => {
      dispatchLoading(dispatch, 'token', false);
    })
    .catch((err) => {});
};

export const REMOVE_FCM_TOKEN = (data) => async (dispatch) => {
  dispatchLoading(dispatch, 'removeToken', true);
  axios
    .post(API_URL + '/api/remove-fcm-token', data)
    .then((result) => {
      dispatchLoading(dispatch, 'removetToken', false);
    })
    .catch((err) => {});
};

export const GetNotificationList = (page, limit, isRead) => async (dispatch) => {
  dispatchLoading(dispatch, 'notification', true);
  axios
    .get(`${API_URL}/notifications`, { params: { page, limit, isRead } })
    .then((result) => {
      if (result?.data?.status) {
        dispatch({
          type: 'FETCH_NOTIFICATION_LIST',
          payload: result?.data?.data,
        });
        dispatchError(dispatch, 'notification', undefined);
      } else elseHandler(dispatch, 'notification', result?.data);
      dispatchLoading(dispatch, 'notification', false);
    })
    .catch((err) => {
      dispatchToast(dispatch, 'error', err?.response?.data?.message);
      dispatchLoading(dispatch, 'notification', false);
    });
};

export const GetNotificationCount = () => async (dispatch) => {
  dispatchLoading(dispatch, 'notification', true);
  axios
    .get(`${API_URL}/notifications/counts`)
    .then((result) => {
      if (result?.data?.status) {
        dispatch({
          type: 'FETCH_NOTIFICATION_COUNT',
          payload: result?.data?.data,
        });
        dispatchError(dispatch, 'notification', undefined);
      } else elseHandler(dispatch, 'notification', result?.data);
      dispatchLoading(dispatch, 'notification', false);
    })
    .catch((err) => {
      dispatchToast(dispatch, 'error', err?.response?.data?.message);
      dispatchLoading(dispatch, 'notification', false);
    });
};

export const UpdateNotificationStatus = (data) => async (dispatch) => {
  dispatchLoading(dispatch, 'notification', true);
  axios
    .put(`${API_URL}/notifications`, data)
    .then((result) => {
      if (result?.data?.status) {
        dispatch(GetNotificationCount());
        dispatchError(dispatch, 'notification', undefined);
      } else elseHandler(dispatch, 'notification', result?.data);
      dispatchLoading(dispatch, 'notification', false);
    })
    .catch((err) => {
      dispatchToast(dispatch, 'error', err?.response?.data?.message);
      dispatchLoading(dispatch, 'notification', false);
    });
};
