import svg from "../Assets/svg";

export const sideBarNavData = [
  {
    id: 1,
    path: "dashboard",
    label: "Dashboard",
    image: svg?.dashboard,
  },
  {
    id: 2,
    path: "shift-management",
    label: "Shift Management",
    image: svg?.shift,
  },
  {
    id: 3,
    path: "client-management",
    label: "Client Management",
    submenu: [
      {
        path: "client-management/client-list",
        label: "Client List",
      },
      {
        path: "client-management/connected-customers",
        label: "Connected Customers",
      },
      {
        path: "client-management/archived-client",
        label: "Archived",
      },
      {
        path: "client-management/expired-documents",
        label: "Expired Documents",
      },
      {
        path: "client-management/add-client",
        label: "Add New Client",
      },
    ],
    image: svg?.clientManage,
  },
  {
    id: 4,
    path: "staff-management",
    label: "Staff Management",
    submenu: [
      {
        path: "staff-management/staff-list",
        label: "Staff List",
      },
      {
        path: "staff-management/teams",
        label: "Teams",
      },
      {
        path: "staff-management/archived-staff",
        label: "Archived",
      },
      {
        path: "staff-management/staff-document",
        label: "Documents Hub",
      },
      {
        path: "staff-management/add-staff",
        label: "Add New Staff",
      },
    ],
    image: svg?.staffManage,
  },
  {
    id: 5,
    path: "task-management",
    label: "Task Management",
    image: svg?.taskManage,
  },
  {
    id: 6,
    path: "invoicing",
    label: "Invoicing",
    submenu: [
      {
        path: "invoicing/list",
        label: "Invoice List",
      },
      {
        path: "invoicing/void-list",
        label: "Void List",
      },
      {
        path: "invoicing/create-invoice",
        label: "Create Invoice",
      },
    ],
    image: svg?.invoicing,
  },
  {
    id: 7,
    path: "reports",
    label: "Reports",
    image: svg?.reports,
    submenu: [
      {
        path: "reports/overall-report",
        label: "Overall Report",
      },
      {
        path: "reports/logistic-report",
        label: "Logistics Report",
      },
      {
        path: "reports/incident-report",
        label: "Incident Report",
      },
      {
        path: "reports/time-sheet",
        label: "Time Sheet",
      },
      {
        path: "reports/client-report",
        label: "Clients Monthly Report",
      },
    ],
  },
  {
    id: 8,
    path: "manage-process",
    label: "Manage Process",
    image: svg?.processManage,
  },
  {
    id: 9,
    path: "company-documents",
    label: "Company Documents",
    image: svg?.companyDoc,
  },
  {
    id: 10,
    path: "settings",
    label: "Settings",
    submenu: [
      {
        path: "settings/general",
        label: "General Settings",
      },
      {
        path: "settings/invoice-settings",
        label: "Invoice Settings",
      },
      {
        path: "settings/prices",
        label: "Prices",
      },
      {
        path: "settings/pay-group",
        label: "Pay Group",
      },
      {
        path: "settings/allowance",
        label: "Allowance",
      },
    ],
    image: svg?.settings,
  },
];

export const clientSidebarData = [
  {
    id: 1,
    path: "dashboard",
    label: "Dashboard",
    image: svg?.dashboard,
  },
  {
    id: 2,
    path: "shift-list",
    label: "Shift List",
    image: svg?.shift,
  },
  {
    id: 2,
    path: "responses",
    label: "Responses",
    image: svg?.response,
  },
  {
    id: 2,
    path: "invoices",
    label: "Invoices",
    image: svg?.invoicing,
  },
  {
    id: 2,
    path: "support",
    label: "Support",
    image: svg?.support,
  },
];
