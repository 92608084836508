import React, { Suspense, lazy, useEffect } from 'react';
import { App as AppWrapper, ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { PrimeReactProvider } from 'primereact/api';

// Store
import store from './store';

// Helpers
import { ErrorFallbackPage, SiteLoader } from './helpers/utils';

// CSS
import 'primereact/resources/themes/lara-light-cyan/theme.css';

// ToastHandler
import ToastHandler from './helpers/ToastHandler';

// Translation
import './helpers/i18n';

// CSS
import './Assets/CSS/common.css';
import './Assets/CSS/style.css';

// Auth CSS
import './Auth/CSS/style.css';

// View CSS
import './View/CSS/style.css';

// Client CSS
import './Client/CSS/style.css';

// Swiper Js
import 'swiper/css';
import 'swiper/css/navigation';

// Navigation
import { clientSidebarData, sideBarNavData } from './Components/NavigationLinkData';

// SafeArea
import { SafeArea } from 'capacitor-plugin-safe-area';

// code push
import { Capacitor } from '@capacitor/core';

import { Loader } from '@googlemaps/js-api-loader';
import { PushNotificationHelper } from './helpers/PushNotificationHelper';
import axios from 'axios';
import { FetchNotification } from './helpers/firebase';

const MyAccount = lazy(() => import('./Staff/MyAccount'));
const MobileLayout = lazy(() => import('./Staff/Components/Layout/MobileLayout'));
const Splash = lazy(() => import('./Staff/Splash'));
const MyShift = lazy(() => import('./Staff/MyShift'));
const MyDoc = lazy(() => import('./Staff/MyDoc'));
const Feedback = lazy(() => import('./Staff/Feedback'));
const StaffShiftDetails = lazy(() => import('./Staff/Components/Shift Details/StaffShiftDetails'));
const ShiftTime = lazy(() => import('./Staff/Components/Shift Details/ShiftTime/ShiftTime'));
const CompanyDocument = lazy(() => import('./Staff/CompanyDocument'));
const About = lazy(() => import('./Staff/About'));
const AddPostIt = lazy(() => import('./Staff/Components/Shift Details/Progress/AddPostIt'));
const AddFeedback = lazy(() => import('./Staff/Components/Shift Details/Progress/AddFeedback'));
const AddShiftNotes = lazy(() => import('./Staff/Timeline/AddShiftNotes'));
const Mileage = lazy(() => import('./Staff/Components/Shift Details/Progress/Mileage'));
const Expense = lazy(() => import('./Staff/Components/Shift Details/Progress/Expense'));
const ReviewLocations = lazy(() => import('./Staff/Timeline/ReviewLocations'));
const AddMileageAndExpense = lazy(() => import('./Staff/Timeline/AddMileageAndExpense'));
const ShiftTask = lazy(() => import('./Staff/Timeline/ShiftTask'));
const ClientsMood = lazy(() => import('./Staff/Timeline/ClientsMood'));
const IncidentOrNearMisses = lazy(() => import('./Staff/Timeline/IncidentOrNearMisses'));
const ShiftRating = lazy(() => import('./Staff/Timeline/ShiftRating'));
const ClientDetails = lazy(() => import('./Staff/ClientDetails'));
const AddProgressNotes = lazy(() => import('./Staff/Components/Shift Details/Progress/AddProgressNotes'));
const Incident = lazy(() => import('./Staff/Components/Shift Details/Progress/Incident'));
const Enquiry = lazy(() => import('./Staff/Components/Shift Details/Progress/Enquiry'));
const Goal = lazy(() => import('./Staff/Components/Shift Details/Progress/Goal'));
const FeedbackDetails = lazy(() => import('./Staff/FeedbackDetails'));
const PostItDetails = lazy(() => import('./Staff/PostItDetails'));
const Notifications = lazy(() => import('./Staff/Notifications'));
const Timeline = lazy(() => import('./Staff/Timeline'));

const root = ReactDOM.createRoot(document.getElementById('root'));

// Auth Pages
const AuthIndex = lazy(() => import('./Auth/AuthIndex'));
const Login = lazy(() => import('./Auth/Pages/Login'));
// const SignUp = lazy(() => import("./Auth/Pages/SignUp"));
const ForgotPassword = lazy(() => import('./Auth/Pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./Auth/Pages/ResetPassword'));
const ResetPasswordSuccess = lazy(() => import('./Auth/Pages/ResetPasswordSuccess'));

// Admin Pages
const AdminIndex = lazy(() => import('./Admin/AdminIndex'));
const Dashboard = lazy(() => import('./Admin/Views/Dashboard'));
const ShiftManagement = lazy(() => import('./Admin/Views/ShiftManagement'));
const ClientList = lazy(() => import('./Admin/Views/ClientManagement/ClientList'));
const ConnectedCustomers = lazy(() => import('./Admin/Views/ClientManagement/ConnectedCustomers'));
const ArchivedClient = lazy(() => import('./Admin/Views/ClientManagement/ArchivedClient'));
const ExpiredDoc = lazy(() => import('./Admin/Views/ClientManagement/ExpiredDoc'));
const AddClient = lazy(() => import('./Admin/Views/ClientManagement/AddClient'));
const AdminClientDetails = lazy(() => import('./Admin/Views/ClientManagement/ClientDetails'));
const StaffList = lazy(() => import('./Admin/Views/StaffManagement/StaffList'));
const Teams = lazy(() => import('./Admin/Views/StaffManagement/Teams'));
const AddTeam = lazy(() => import('./Admin/Views/StaffManagement/AddTeam'));
const ArchivedStaff = lazy(() => import('./Admin/Views/StaffManagement/ArchivedStaff'));
const StaffDoc = lazy(() => import('./Admin/Views/StaffManagement/StaffDoc'));
const AddStaff = lazy(() => import('./Admin/Views/StaffManagement/AddStaff'));
const StaffDetails = lazy(() => import('./Admin/Views/StaffManagement/StaffDetails'));
const TaskManagement = lazy(() => import('./Admin/Views/TaskManagement'));
const ManageProcess = lazy(() => import('./Admin/Views/ManageProcess'));
const CompanyDocuments = lazy(() => import('./Admin/Views/CompanyDocuments'));
const GeneralSettings = lazy(() => import('./Admin/Views/SettingsView/GeneralSettings'));
const InvoiceSettings = lazy(() => import('./Admin/Views/SettingsView/InvoiceSettings'));
const EditInvoiceSettings = lazy(() => import('./Admin/Views/SettingsView/EditInvoiceSettings'));
const Prices = lazy(() => import('./Admin/Views/SettingsView/Prices'));
const PayGroup = lazy(() => import('./Admin/Views/SettingsView/PayGroup'));
const Allowance = lazy(() => import('./Admin/Views/SettingsView/Allowance'));
const ChangePassword = lazy(() => import('./Admin/Views/ChangePassword'));
const Profile = lazy(() => import('./Admin/Views/Profile'));
const EditProfile = lazy(() => import('./Admin/Views/EditProfile'));
const ArchivedPrices = lazy(() => import('./Admin/Views/SettingsView/ArchivedPrices'));
const ArchivedPayGroup = lazy(() => import('./Admin/Views/SettingsView/ArchivedPayGroup'));
const InvoiceList = lazy(() => import('./Admin/Views/Invoices/InvoiceList'));
const VoidInvoices = lazy(() => import('./Admin/Views/Invoices/VoidInvoices'));
const CreateInvoice = lazy(() => import('./Admin/Views/Invoices/CreateInvoice'));
const CompleteInvoice = lazy(() => import('./Admin/Views/Invoices/CompleteInvoice'));
const ShiftDetails = lazy(() => import('./Admin/Views/ShiftManagement/ShiftDetails'));
const EditInvoice = lazy(() => import('./Admin/Views/Invoices/EditInvoice'));
const OverallReport = lazy(() => import('./Admin/Views/Report/Overall'));
const LogisticReport = lazy(() => import('./Admin/Views/Report/Logistic'));
const IncidentReport = lazy(() => import('./Admin/Views/Report/Incident'));
const TimeSheetReport = lazy(() => import('./Admin/Views/Report/TimeSheet'));
const ClientReport = lazy(() => import('./Admin/Views/Report/Client'));
const ImportPrice = lazy(() => import('./Admin/Views/SettingsView/ImportPrices'));
const AdminNotification = lazy(() => import('./Admin/Views/AdminNotification'));

//Client Pages:
const ClientIndex = lazy(() => import('./Client/Index'));
const CDashboard = lazy(() => import('./Client/Views/Dashboard'));
const CShiftList = lazy(() => import('./Client/Views/ShiftList'));
const CResponse = lazy(() => import('./Client/Views/Response'));
const CInvoice = lazy(() => import('./Client/Views/Invoice'));
const CSupport = lazy(() => import('./Client/Views/Support'));
const CShiftDetail = lazy(() => import('./Client/Views/ClientStaffDetails'));
const CNotification = lazy(() => import('./Client/Views/ClientNotification'));

/** Error Page */
const PageNotFound = lazy(() => import('./Components/Pages/Error'));

//Static Pages:
const StaticPage = lazy(() => import('./Admin/Views/StaticPage'));

let height;

/** SafeArea for ios*/

const inset = await SafeArea.getSafeAreaInsets();
await SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
  const root = document.body;
  root.style.setProperty('margin-top', `${statusBarHeight}px`);
  root.style.setProperty('height', `calc(100% - ${statusBarHeight}px - ${inset.insets.bottom}px)`);
  height = statusBarHeight;
});
await SafeArea.removeAllListeners();

// when safe-area changed
await SafeArea.addListener('safeAreaChanged', (data) => {
  const { insets } = data;
  for (const [key, value] of Object.entries(insets)) {
    document.documentElement.style.setProperty(`--safe-area-inset-${key}`, `${value}px`);
  }
});

const MainApp = () => {
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    console.log('Mobile device');
    const handleMobileRedirect = async () => {
      const path = window.location.pathname;

      if (path !== '/staff') {
        window.location.pathname = '/staff';
      }
    };

    handleMobileRedirect();
  }, []);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_MAP_KEY,
      libraries: ['places'],
    });

    const importMaps = loader.importLibrary('maps');
    const importPlaces = loader.importLibrary('places');

    Promise.all([importMaps, importPlaces]).then(
      () => console.info('Google Maps loaded with status ', loader.status),
      () => console.warn('Google Maps failed to load')
    );
  }, []);

  return (
    <React.StrictMode>
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Montserrat',
            colorPrimary: '#3EA28D',
          },
        }}
      >
        <AppWrapper>
          <Provider store={store}>
            <PrimeReactProvider>
              <BrowserRouter>
                <Suspense fallback={<SiteLoader />}>
                  <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
                    <FetchNotification />
                    {/* Route List */}
                    <Routes>
                      {/* Auth */}
                      <Route path="/" element={<Login />} />
                      <Route path="/auth" element={<AuthIndex />}>
                        <Route path="login" element={<Login />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="forgot-password/:token" element={<ResetPassword />} />
                        <Route path="reset-password-success" element={<ResetPasswordSuccess />} />
                      </Route>
                      {/* Auth */}

                      {/* View */}
                      {/* <Route path="/" element={<ViewIndex roleName={""} />}>
  <Route exact path="" element={<>Home</>} />
  <Route path="*" element={<PageNotFound />} />
</Route> */}
                      {/* View */}

                      {/* Admin */}
                      <Route
                        path="/admin"
                        element={<AdminIndex roleName={'business_admin'} navigationData={sideBarNavData} />}
                      >
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="shift-management" element={<ShiftManagement />} />
                        <Route path="shift-management/shift-details" element={<ShiftDetails />} />

                        <Route path="client-management/client-list" element={<ClientList />} />
                        <Route path="client-management/client-details/:clientID" element={<AdminClientDetails />} />
                        <Route path="client-management/connected-customers" element={<ConnectedCustomers />} />
                        <Route path="client-management/archived-client" element={<ArchivedClient />} />
                        <Route path="client-management/expired-documents" element={<ExpiredDoc />} />
                        <Route path="client-management/add-client" element={<AddClient />} />

                        <Route path="staff-management/staff-list" element={<StaffList />} />
                        <Route path="staff-management/staff-details/:staffID" element={<StaffDetails />} />
                        <Route path="staff-management/teams" element={<Teams />} />
                        <Route path="staff-management/archived-staff" element={<ArchivedStaff />} />
                        <Route path="staff-management/staff-document" element={<StaffDoc />} />
                        <Route path="staff-management/add-staff" element={<AddStaff />} />
                        <Route path="staff-management/add-team" element={<AddTeam />} />
                        <Route path="staff-management/edit-team/:teamId" element={<AddTeam />} />

                        <Route path="task-management" element={<TaskManagement />} />

                        <Route path="invoicing/list" element={<InvoiceList />} />
                        <Route path="invoicing/void-list" element={<VoidInvoices />} />
                        <Route path="invoicing/create-invoice" element={<CreateInvoice />} />
                        <Route
                          path="invoicing/create-invoice/complete/:clientId/:invoiceId"
                          element={<CompleteInvoice />}
                        />
                        <Route path="invoicing/create-invoice/edit/:clientId/:invoiceId" element={<EditInvoice />} />

                        <Route path="reports/overall-report" element={<OverallReport />} />
                        <Route path="reports/logistic-report" element={<LogisticReport />} />
                        <Route path="reports/incident-report" element={<IncidentReport />} />
                        <Route path="reports/time-sheet" element={<TimeSheetReport />} />
                        <Route path="reports/client-report" element={<ClientReport />} />

                        <Route path="manage-process" element={<ManageProcess />} />
                        <Route path="company-documents" element={<CompanyDocuments />} />

                        <Route path="settings/general" element={<GeneralSettings />} />
                        <Route path="settings/invoice-settings" element={<InvoiceSettings />} />
                        <Route path="settings/edit-invoice-settings" element={<EditInvoiceSettings />} />
                        <Route path="settings/prices" element={<Prices />} />
                        <Route path="settings/prices/import" element={<ImportPrice />} />
                        <Route path="settings/prices/archived-prices" element={<ArchivedPrices />} />
                        <Route path="settings/pay-group" element={<PayGroup />} />
                        <Route path="settings/pay-group/archived-pay-group" element={<ArchivedPayGroup />} />
                        <Route path="settings/allowance" element={<Allowance />} />
                        <Route path="*" element={<PageNotFound />} />

                        <Route path="profile" element={<Profile />} />
                        <Route path="edit-profile" element={<EditProfile />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="notifications" element={<AdminNotification />} />
                      </Route>
                      {/* Admin */}

                      {/* User */}
                      <Route
                        path="/client"
                        element={<ClientIndex roleName={'client'} navigationData={clientSidebarData} />}
                      >
                        <Route path="dashboard" element={<CDashboard />} />
                        <Route path="shift-list">
                          <Route path="" element={<CShiftList />} />
                          <Route path="shift-detail/:staffId" element={<CShiftDetail />} />
                        </Route>
                        <Route path="responses" element={<CResponse />} />
                        <Route path="invoices" element={<CInvoice />} />
                        <Route path="support" element={<CSupport />} />
                        <Route path="edit-profile" element={<EditProfile route={'client'} />} />
                        <Route path="profile" element={<Profile route={'client'} />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="notifications" element={<CNotification />} />
                      </Route>
                      {/* User */}

                      {/* Staff */}
                      <Route path="/staff" element={<Splash />} />
                      <Route path="/staff" element={<MobileLayout showHeader={false} showNavigation={true} />}>
                        <Route path="more/company-document" element={<CompanyDocument />} />
                        <Route path="more/about" element={<About />} />
                      </Route>

                      <Route path="/staff" element={<MobileLayout showHeader={false} showNavigation={false} />}>
                        <Route path="client-details/:clientId" element={<ClientDetails />} />
                        <Route path="shift-details/:shiftId" element={<StaffShiftDetails />} />
                        <Route path="shift-details/shift-time/:shiftId" element={<ShiftTime />} />
                        <Route path="my-account" element={<MyAccount />} />
                        <Route path="add-post-it" element={<AddPostIt />} />
                        <Route path="notifications" element={<Notifications />} />
                        <Route path="shift-details/:shiftId/progress/add-feedback" element={<AddFeedback />} />
                        <Route
                          path="shift-details/:shiftId/progress/add-progress-notes"
                          element={<AddProgressNotes />}
                        />
                        <Route path="shift-details/:shiftId/progress/add-incident" element={<Incident />} />
                        <Route path="shift-details/:shiftId/progress/add-enquiry" element={<Enquiry />} />
                        <Route path="shift-details/:shiftId/timeline/add-shift-notes" element={<AddShiftNotes />} />
                        <Route path="shift-details/:shiftId/progress/mileage" element={<Mileage />} />
                        <Route path="shift-details/:shiftId/progress/expense" element={<Expense />} />
                        <Route path="shift-details/:shiftId/progress/add-goal" element={<Goal />} />
                        <Route path="shift-details/:shiftId/timeline/review-locations" element={<ReviewLocations />} />
                        <Route
                          path="shift-details/:shiftId/timeline/add-mileage-and-expense"
                          element={<AddMileageAndExpense />}
                        />
                        <Route path="shift-details/:shiftId/timeline/shift-task" element={<ShiftTask />} />
                        <Route path="shift-details/:shiftId/timeline/clients-mood" element={<ClientsMood />} />
                        <Route
                          path="shift-details/:shiftId/timeline/incident-or-near-miss"
                          element={<IncidentOrNearMisses />}
                        />
                        <Route path="shift-details/:shiftId/timeline/shift-rating" element={<ShiftRating />} />
                        <Route path="feedback-details/:shiftId/:noteId" element={<FeedbackDetails />} />
                        <Route path="post-it-details/:noteId" element={<PostItDetails />} />
                        <Route path="timeline/:shiftId" element={<Timeline />} />
                      </Route>

                      <Route path="/staff" element={<MobileLayout showNavigation={true} />}>
                        <Route path="my-shift" element={<MyShift />} />
                        <Route path="my-doc" element={<MyDoc />} />
                        <Route path="feedback" element={<Feedback />} />
                      </Route>

                      <Route path="/staff/auth" element={<AuthIndex />}>
                        <Route path="login" element={<Login />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="forgot-password/:token" element={<ResetPassword />} />
                        <Route path="reset-password-success" element={<ResetPasswordSuccess />} />
                      </Route>
                      {/* Staff */}

                      {/* Static Pages */}
                      <Route path="page/:url" element={<StaticPage />} />
                      {/* Static Pages */}

                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                    {/* Route List End */}
                  </ErrorBoundary>
                </Suspense>
                <PushNotificationHelper />
              </BrowserRouter>
              <ToastHandler />
            </PrimeReactProvider>
          </Provider>
        </AppWrapper>
      </ConfigProvider>
    </React.StrictMode>
  );
};

const UNAUTHORIZED = 406;
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    if (status === UNAUTHORIZED) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.setItem('isLoggedIn', false);
      store.dispatch({ type: 'AUTH_LOGOUT_SUCCESS' });

      const currentUrl = window.location.href;
      const baseUrl = window.location.origin;
      if (currentUrl.includes('/staff')) {
        window.location.href = `${baseUrl}/staff/auth/login`;
      } else {
        window.location.href = baseUrl;
      }
    }
    return Promise.reject(error);
  }
);
root.render(<MainApp />);
